<template>
  <div :class="animation">
    <b-card title="Moyen de change">
      <b-row>
        <b-col
          sm="6"
          class="p-1"
        >
          <v-select
            v-model="$v.selectedBuyCurrency.$model"
            :class="{
              'is-invalid': $v.selectedBuyCurrency.$error,
            }"
            :clearable="true"
            label="title"
            :options="buyCurrenciesOptions"
          >
            <template #header>
              <div class="text-primary">
                Je donne !!!</div>
            </template>
            <template v-slot:option="option">
              <img
                :src="option.src"
                class="mr-1 float-left"
                width="45"
                height="45"
              >
              <span>{{ option.data.libelle }}</span> <br>
              <span>{{ option.data.buy }} FCFA </span>
            </template>
          </v-select>
          <div
            v-if="$v.selectedBuyCurrency.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.selectedBuyCurrency.required">{{
              $t("errors.required")
            }}</span>
          </div>
        </b-col>
        <b-col
          sm="6"
          class="p-1"
        >
          <v-select
            v-model="$v.selectedSellCurrency.$model"
            :class="{
              'is-invalid': $v.selectedSellCurrency.$error,
            }"
            :clearable="true"
            label="title"
            :options="sellCurrenciesOptions"
          >
            <!-- :selectable="(option) => option.data.solde > 0" -->
            <template #header>
              <div class="text-primary">
                Je reçois !!!</div>
            </template>
            <template v-slot:option="option">
              <img
                :src="option.src"
                class="mr-1 float-left"
                width="45"
                height="45"
              >
              <span>{{ option.data.libelle }}</span> <br>
              <span>{{ checkCountry(option.data.sale, option.data.provider) }} {{ checkFranc(option.data.dev_code) ? 'FCFA' : 'GNF' }}</span>
            </template>
          </v-select>
          <div
            v-if="$v.selectedSellCurrency.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.selectedSellCurrency.required">{{
              $t("errors.required")
            }}</span>
          </div>
        </b-col>

        <b-col
          v-if="sellItem && buyItem"
          sm="6"
        >
          <b-form-group>
            <label>
              <span v-if="isDollar(buy)">
                Montant à échanger (<span>{{ checkCurrency(buyItem.data.dev_code) }}</span>)
              </span>
              <span v-else>
                Montant à échanger (<span>{{ checkFranc(buyItem.data.dev_code) ? 'FCFA' : 'GNF' }}</span>)
              </span>
            </label>
            <b-form-input
              id="get-buy-fees"
              v-model.number="$v.sendAmount.$model"
              type="number"
              aria-describedby="get-buy-fees"
              :class="{
                'is-invalid': $v.sendAmount.$error,
                'is-valid': !$v.sendAmount.$invalid && buyItem.data.dev_code === 'OM_BURKINA',
              }"
            />
            <!-- <b-form-text
              v-if="!$v.sendAmount.$invalid && !isDollar(buy)"
              id="get-buy-fees"
            >
              <p class="text-primary">
                Frais réseau :  <span class="text-danger">{{ (getBuyFees ) > 0 ? ( getBuyFees) : 0 }} </span> <span>{{ checkFranc(buyItem.data.dev_code) ? 'FCFA' : 'GNF' }}</span></p>
            </b-form-text> -->
            <div
              v-if="$v.sendAmount.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.sendAmount.required">{{
                $t("errors.required")
              }}<br></span>
              <span v-if="!$v.sendAmount.checkMin">
                Le minimum à envoyer est
                <span v-if="isDollar(buy) && buyItem.data.is_crypto">{{ minForCrypto }} {{ checkCurrency(buyItem.data.dev_code) }}</span>
                <span v-else-if="isDollar(buy) && !isDollar(sell)">{{ minForDollar }} {{ checkCurrency(buyItem.data.dev_code) }}</span>
                <span v-else-if="isDollar(buy) && isDollar(sell) && !sellItem.data.is_crypto">{{ minForDollar }} {{ checkCurrency(buyItem.data.dev_code) }}</span>
                <span v-else-if="isDollar(buy) && isDollar(sell) && sellItem.data.is_crypto">{{ minDollarToCrypto }} {{ checkCurrency(buyItem.data.dev_code) }}</span>
                <span v-else-if="!isDollar(buy) && checkFranc(buyItem.data.dev_code) && sellItem.data.is_crypto && (sellItem.data.dev_code === 'BTC' || sellItem.data.dev_code === 'BCH')">{{ minForFCFAtoBTCCrypto }} XOF</span>
                <span v-else-if="!isDollar(buy) && checkFranc(buyItem.data.dev_code) && sellItem.data.is_crypto && (sellItem.data.dev_code !== 'BTC' || sellItem.data.dev_code !== 'BCH')">{{ minForFCFAtoCrypto }} XOF</span>
                <span v-else-if="!isDollar(buy) && !checkFranc(buyItem.data.dev_code) && isDollar(sell) && sellItem.data.is_crypto && (sellItem.data.dev_code === 'BTC' || sellItem.data.dev_code === 'BCH')">{{ minForGNFtoBTCCrypto }} GNF</span>
                <span v-else-if="!isDollar(buy) && !checkFranc(buyItem.data.dev_code) && isDollar(sell) && sellItem.data.is_crypto && (sellItem.data.dev_code !== 'BTC' || sellItem.data.dev_code !== 'BCH')">{{ minForGNFtoCrypto }} GNF</span>
                <span v-else-if="!isDollar(buy) && !checkFranc(buyItem.data.dev_code) && (isDollar(sell)) && !sellItem.data.is_crypto">{{ minForGNF }} GNF</span>
                <span v-else-if="!isDollar(buy) && !checkFranc(buyItem.data.dev_code) && !isDollar(sell) && !checkFranc(sellItem.data.dev_code)">{{ minForGNFtoGNF }} GNF</span>
                <span v-else>{{ minForFCFA }} {{ checkFranc(buyItem.data.dev_code) ? 'FCFA' : 'GNF' }}</span>
                <br></span>
              <span v-if="!$v.sendAmount.checkOrangeAmount">
                Le montant à échanger doit se terminer par 0 ou par 5.
              </span>
            </div>
            <div
              v-if="!$v.sendAmount.$invalid && buyItem.data.dev_code === 'OM_BURKINA'"
              class="valid-feedback"
            >
              <span>Veuillez générer un OTP de {{ getBuyFees + sendAmount }} FCFA.</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          v-if="sellItem && buyItem"
          sm="6"
        >
          <b-form-group>
            <label>
              <span v-if="isDollar(sell)">
                Montant à recevoir (<span>{{ checkCurrency(sellItem.data.dev_code) }}</span>)
              </span>
              <span v-else>
                Montant à recevoir (<span>{{ checkFranc(sellItem.data.dev_code) ? 'FCFA' : 'GNF' }}</span>)
              </span>
            </label>
            <b-form-input
              id="get-sale-fees"
              v-model.number="$v.getReceivedAmount.$model"
              type="number"
              :class="{
                'is-invalid': $v.getReceivedAmount.$error,
              }"
              class="border-warning font-weight-bolder text-large"
              disabled
              aria-describedby="get-sale-fees"
            />
            <div
              v-if="$v.getReceivedAmount.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.getReceivedAmount.required">{{
                $t("errors.required")
              }}</span>
              <span v-if="!$v.getReceivedAmount.checkMax">
                Le solde disponible est insuffisant
              </span>
              <!-- <span v-if="!$v.getReceivedAmount.minValue">
                Le montant minimum pour une transaction du système est
                <span v-if="isDollar(sell)">{{ minForDollar }} $</span>
                <span v-else>{{ minForFCFA }} {{ checkFranc(sellItem.data.dev_code) ? 'FCFA' : 'GNF' }}</span>
                <br>
              </span> -->
            </div>
          </b-form-group>
        </b-col>
        <b-col
          v-if="sellItem && buyItem && !isDollar(buy)"
          sm="6"
        >
          <b-form-group label="N° Téléphone d'envoi">
            <vue-tel-input
              v-model.trim="$v.senderAdress.$model"
              :class="{
                'is-invalid': $v.senderAdress.$error,
              }"
              @validate="checkSendValidity($event)"
            >
              <template #arrow-icon>
                <span />
              </template>
            </vue-tel-input>
            <div
              v-if="$v.senderAdress.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.senderAdress.required">{{
                $t("errors.required")
              }}<br></span>
              <span v-if="!$v.senderAdress.checkCorrectFormat">
                Format invalide
              </span>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          v-if="sellItem && buyItem && !isDollar(buy) && showWaveBuyInfo"
          sm="6"
        >
          <b-form-group label="Nom complet de l'expéditeur">
            <b-form-input
              v-model="$v.senderAddressName.$model"
              :class="{
                'is-invalid': $v.senderAddressName.$error,
              }"
              :formatter="formatter"
            />
            <div
              v-if="$v.senderAddressName.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.senderAddressName.required">{{
                $t("errors.required")
              }}</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          v-if="sellItem && buyItem && !isDollar(buy) && showWaveBuyInfo"
          sm="6"
        >
          <b-form-group label="N° de paiment WAVE">
            <b-form-select
              v-model="$v.manuelPaymentAddress.$model"
              :options="addressOptions"
              :class="{
                'is-invalid': $v.manuelPaymentAddress.$error,
              }"
            />
            <div
              v-if="$v.manuelPaymentAddress.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.manuelPaymentAddress.required">{{
                $t("errors.required")
              }}</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          v-if="sellItem && buyItem && (sellItem.data.dev_code !== 'VISA')"
          sm="6"
        >
          <b-form-group
            :label="isDollar(sell) ? 'Adresse de réception' : 'N° Téléphone de réception'"
          >
            <template #description>
              <span
                v-if="sellItem.data.is_crypto && sellItem.data.provider === 'COINBASE'"
                class="text-danger text-justify"
              >
                Avis aux utilisateurs du Wallet Coinbase: <br>
                Pour une réception instantanée de vos {{ sellItem.data.libelle }}, Prière renseignez l'adresse email liée au Wallet coinbase.<br>
                Renseignez votre adresse {{ sellItem.data.libelle }} si vous n'utilisez pas un  Wallet coinbase
              </span>
              <span
                v-if="sellItem.data.is_crypto && sellItem.data.provider === 'COINPAYMENT'"
                class="text-danger text-justify"
              >
                Renseignez votre adresse {{ sellItem.data.libelle }}.
              </span>
              <span
                v-if="isDollar(sell) && !sellItem.data.is_crypto"
                class="text-danger text-justify"
              >
                <span v-if="sellItem.data.dev_code === 'PM_EURO' || sellItem.data.dev_code === 'PM_USD'">
                  Veuillez fournir votre adresse UXXXXXXXXX de votre compte Perfect Money.
                </span>
                <span v-if="sellItem.data.dev_code === 'PAYEER_EURO' || sellItem.data.dev_code === 'PAYEER_USD'">
                  Veuillez fournir votre adresse PXXXXXXXXX de votre compte Payeer.
                </span>
              </span>
            </template>
            <b-form-input
              v-if="isDollar(sell)"
              v-model.trim="$v.receiveAddress.$model"
              :class="{
                'is-invalid': $v.receiveAddress.$error,
              }"
              type="text"
            />
            <vue-tel-input
              v-else
              v-model.trim="$v.receiveAddress.$model"
              :class="{
                'is-invalid': $v.receiveAddress.$error,
              }"
              @validate="checkReceivedValidity($event)"
            >
              <template #arrow-icon>
                <span />
              </template>
            </vue-tel-input>
            <div
              v-if="$v.receiveAddress.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.receiveAddress.required">{{
                $t("errors.required")
              }}<br></span>
              <span v-if="!$v.receiveAddress.checkCorrectFormat">
                Format invalide
              </span>
            </div>
          </b-form-group>

        </b-col>
        <b-col
          v-if="sellItem && buyItem && !isDollar(sell) && (showVisaInfo || showWaveSellInfo)"
          sm="6"
        >
          <b-form-group :label="showWaveSellInfo ? 'Nom complet du receveur' : 'Nom complet du propriétaire de la carte'">
            <b-form-input
              v-model="$v.receiverAddressName.$model"
              :class="{
                'is-invalid': $v.receiverAddressName.$error,
              }"
              :formatter="formatter"
            />
            <div
              v-if="$v.receiverAddressName.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.receiverAddressName.required">{{
                $t("errors.required")
              }}</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          v-if="sellItem && buyItem && !isDollar(sell) && showVisaInfo"
          sm="6"
        >
          <b-form-group
            label="Quatre derniers chiffres du numéro de la carte"
            description="Les 4 derniers chiffres des 16 chiffres qui representent le numero de la carte"
          >
            <b-form-input
              v-model="$v.visaLastDigit.$model"
              :class="{
                'is-invalid': $v.visaLastDigit.$error,
              }"
              type="number"
            />
            <div
              v-if="$v.visaLastDigit.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.visaLastDigit.required">{{
                $t("errors.required")
              }}</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          v-if="sellItem && buyItem && !isDollar(sell) && showVisaInfo"
          sm="6"
        >
          <b-form-group
            label="Identifiant de la carte"
            description="Ce nombre se trouve derriere votre carte"
          >
            <b-form-input
              v-model="$v.visaCardId.$model"
              :class="{
                'is-invalid': $v.visaCardId.$error,
              }"
              type="number"
            />
            <div
              v-if="$v.visaCardId.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.visaCardId.required">{{
                $t("errors.required")
              }}</span>
            </div>
          </b-form-group>
        </b-col>

        <b-col
          v-if="sellItem && buyItem && buyItem.data.has_otp"
          sm="6"
        >
          <b-form-group
            label="OTP"
          >
            <b-form-input
              v-model="$v.otp.$model"
              type="number"
              :class="{
                'is-invalid': $v.otp.$error,
              }"
            />
            <div
              v-if="$v.otp.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.otp.required">{{
                $t("errors.required")
              }}</span>
            </div>
          </b-form-group>
        </b-col>

        <b-col
          sm="12"
        >
          <!-- <div class="text-center">
            <b-spinner
              v-if="process"
              variant="primary"
            />
          </div> -->
          <div class="text-right mt-2">
            <b-button
              variant="outline-primary"
              :disabled="process"
              @click="tryToExchange"
            >
              <span>{{ $t("form.exchange") }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      v-if="showModal"
      id="modalId"
      ref="modal"
      v-model="showModal"
      title="Récapitulatif"
      :no-close-on-backdrop="process || closeProcess || loaded"
      :no-close-on-esc="process || closeProcess || loaded"
      :hide-header-close="process || closeProcess || loaded"
      hide-footer
      centered
      @close="closeDetailsModal"
    >
      <b-row :class="animation">
        <b-col>
          <p>Envoi</p>
          <b-table-simple responsive>
            <tr>
              <td>Je donne</td>
              <td>
                {{ buyItem.data.libelle }}
              </td>
            </tr>
            <tr>
              <td>
                Montant à échanger
              </td>
              <td>
                <b-badge
                  variant="primary"
                  pill
                >
                  <span v-if="isDollar(buy)">
                    {{ sendAmount }} <span>{{ checkCurrency(buyItem.data.dev_code) }}</span>
                  </span>
                  <span v-else>
                    {{ sendAmount }} <span>{{ checkFranc(buyItem.data.dev_code) ? 'FCFA' : 'GNF' }}</span>
                  </span>
                </b-badge>
              </td>
            </tr>
            <tr v-if="!isDollar(buy)">
              <td v-if="buyItem.data.dev_code === 'WAVE_SENEGAL' || buyItem.data.dev_code === 'WAVE_CI'">
                Frais WAVE</td>
              <td v-else>
                Frais réseau</td>
              <td>
                <b-badge
                  variant="warning"
                  pill
                >
                  {{ getBuyFees }} <span> {{ checkFranc(buyItem.data.dev_code) ? 'FCFA' : 'GNF' }} </span>
                </b-badge>
              </td>
            </tr>
            <tr v-if="buyItem.data.has_otp">
              <td>Montant a générer pour OTP</td>
              <td>
                {{ getBuyFees + sendAmount }}
              </td>
            </tr>
            <tr v-if="buyItem.data.has_otp">
              <td>OTP</td>
              <td>
                {{ otp }}
              </td>
            </tr>
            <tr v-if="!isDollar(buy)">
              <td>Total à payer</td>
              <td>
                <b-badge
                  variant="danger"
                  pill
                >
                  {{ getBuyFees + sendAmount }} <span> {{ checkFranc(buyItem.data.dev_code) ? 'FCFA' : 'GNF' }} </span>
                </b-badge>
              </td>
            </tr>
            <tr v-if="sender">
              <td>Adresse d'envoi</td>
              <td>
                {{ sender ? sender : senderAdress }}
              </td>
            </tr>
            <tr v-if="showWaveBuyInfo">
              <td>Expéditeur</td>
              <td>
                {{ senderAddressName }}
              </td>
            </tr>
            <tr v-if="showWaveBuyInfo">
              <td>N° de paiment WAVE</td>
              <td>
                <span
                  id="copy"
                  v-b-tooltip.hover.top="'Cliquer pour copier!'"
                  class="cursor"
                  @click="onCopy(sendManualAddressName)"
                >
                  <b-icon
                    class="text-primary"
                    icon="clipboard"
                    font-scale="1"
                  />
                  {{ sendManualAddressName }}
                </span>
              </td>
            </tr>
          </b-table-simple>

          <p>Réception</p>
          <b-table-simple responsive>
            <tr>
              <td>Je reçois</td>
              <td>
                {{ sellItem.data.libelle }}
              </td>
            </tr>
            <tr v-if="!isDollar(sell)">
              <td v-if="sellItem.data.dev_code === 'VISA'">
                Frais UBA</td>
              <td v-else-if="sellItem.data.dev_code === 'WAVE_SENEGAL' || sellItem.data.dev_code === 'WAVE_CI'">
                Frais total</td>
              <td v-else>
                Frais fixe</td>
              <td>
                <b-badge
                  variant="warning"
                  pill
                >
                  {{ getSaleFees + systemFees }} <span> {{ checkFranc(sellItem.data.dev_code) ? 'FCFA' : 'GNF' }} </span>
                </b-badge>
              </td>
            </tr>
            <tr>
              <td>
                Montant à recevoir
              </td>
              <td>
                <b-badge
                  variant="primary"
                  pill
                >
                  <span v-if="isDollar(sell)">
                    {{ getReceivedAmount }} <span>{{ checkCurrency(sellItem.data.dev_code) }}</span>
                  </span>
                  <span v-else>
                    {{ getReceivedAmount }} <span>{{ checkFranc(sellItem.data.dev_code) ? 'FCFA' : 'GNF' }}</span>
                  </span>
                </b-badge>
              </td>
            </tr>
            <tr v-if="sellItem.data.is_crypto && sellItem.data.provider === 'COINPAYMENT' && sellItem.data.partner_fees_out !== 0">
              <td>
                Frais réseau</td>
              <td>
                <b-badge
                  variant="warning"
                  pill
                >
                  {{ sellItem.data.partner_fees_out }} <span> {{ sellItem.data.dev_code }} </span> ~
                  {{ cryptoConverted +' USD' }}
                </b-badge>
              </td>
            </tr>
            <tr v-if="sellItem.data.is_crypto && sellItem.data.provider === 'COINPAYMENT' && sellItem.data.partner_fees_out !== 0">
              <td>
                Total (approximatif)
              </td>
              <td>
                <b-badge
                  variant="danger"
                  pill
                >
                  {{ (getReceivedAmount - cryptoConverted).toFixed(2) +' $' }}
                </b-badge>
              </td>
            </tr>
            <tr v-if="!showVisaInfo">
              <td>Adresse de réception</td>
              <td>
                {{ receiver ? receiver : receiveAddress }}
              </td>
            </tr>
            <tr v-if="showWaveSellInfo">
              <td>Receveur</td>
              <td>
                {{ receiverAddressName }}
              </td>
            </tr>
            <tr v-if="showVisaInfo">
              <td>Propriétaire</td>
              <td>
                {{ receiverAddressName }}
              </td>
            </tr>
            <tr v-if="showVisaInfo">
              <td>Identifiant de la carte</td>
              <td>
                {{ visaCardId }}
              </td>
            </tr>
            <tr v-if="showVisaInfo">
              <td>Numero de Carte (4 derniers chiffres)</td>
              <td>
                {{ visaLastDigit }}
              </td>
            </tr>
            <tr v-if="buyItem.data.has_otp">
              <td>Montant a générer pour OTP</td>
              <td>
                {{ getBuyFees + sendAmount }}
              </td>
            </tr>
            <tr v-if="buyItem.data.has_otp">
              <td>OTP</td>
              <td>
                {{ otp }}
              </td>
            </tr>
          </b-table-simple>
          <div
            v-if="sellItem && buyItem && !isDollar(buy) && showWaveBuyInfo"
            class="w-100"
          >
            <b-form-group
              label="Image"
              description="Capture d'écran du transfert Wave validé"
            >
              <b-form-file
                v-model="$v.file.$model"
                :value="file"
                browse-text="Parcourir"
                placeholder="Choisir le fichier"
                accept=".jpg, .png"
                :class="{'is-invalid':$v.file.$error}"
                @change="uploadFile($event)"
              />
              <b-progress
                v-if="loadImage"
                variant="primary"
                height="3px"
                :value="progress"
                :max="100"
                class="w-100 mt-1"
              />
              <div
                v-if="this.$v.file.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.file.required">L'image est obigatoire<br></span>
                <span v-if="!$v.file.checkSize">Taille maximale: 5MB</span>
              </div>
            </b-form-group>
          </div>
          <p v-if="!isDollar(sell)">
            Les frais de transfert sont automatiquement appliqués
          </p>

          <p class="text-primary font-weight-lighter">
            <!-- Manuel a Manuel -->
            <span v-if="!buyItem.data.is_auto && !sellItem.data.is_auto">
              NB: Cette opération est manuelle et peut durer quelques minutes (5 à 30 min) pour le traitment.
            </span>
            <span v-if="!buyItem.data.is_auto && !buyItem.data.is_crypto && sellItem.data.is_auto">
              NB: Cette opération est semi-automatique et peut durer quelques minutes (5 à 30 min) pour le traitment.
            </span>
            <span v-if="buyItem.data.is_auto && !sellItem.data.is_auto">
              NB: Cette opération est semi-automatique et peut durer quelques minutes (5 à 30 min) pour le traitment.
            </span>
          </p>
          <b-form-checkbox
            id="checkbox-1"
            v-model="confirm"
            name="checkbox-1"
            :value="true"
            :unchecked-value="false"
          >
            J'accepte les conditions d'échange de la plateforme
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div
            v-if="process || loaded || closeProcess"
            class="text-center mt-2 mb-1"
          >
            <div>
              <b-spinner variant="primary" />
              <p>Transaction en cours ...</p>
            </div>
          </div>

          <div
            class="mt-1"
          >
            <b-button
              :disabled="process || loaded || closeProcess || !confirm || loadImage"
              class="form-control"
              variant="primary"
              @click="confirmExchange"
            >
              Confirmer l'envoi
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      v-if="showCoinbaseModal"
      id="modalId"
      ref="modal"
      v-model="showCoinbaseModal"
      title="Paiment BTC"
      hide-footer
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      centered
      @close="closeCoinbaseModal"
    >
      <b-row :class="animation">
        <b-col>
          <b-table-simple responsive>
            <caption>Cliquer pour copier les valeurs</caption>
            <tr>
              <td>Adresse</td>
              <td
                id="btc-address"
                v-b-tooltip.hover.top="'Cliquer pour copier!'"
                @click="onAddressCopy(coinbaseData.address.address)"
              >
                {{ truncate(coinbaseData.address.address) }}
              </td>
            </tr>
            <tr>
              <td>Valeur en BTC</td>
              <td
                id="btc-value"
                v-b-tooltip.hover.top="'Cliquer pour copier!'"
                @click="onValueCopy(coinbaseData.data.coinSentValue)"
              >
                {{ coinbaseData.data.coinSentValue }}
              </td>
            </tr>
          </b-table-simple>
        </b-col>
      </b-row>
      <p class="text-warning">
        NB: {{ coinbaseData.address.inline_warning.text }}
      </p>
      <div
        class="mt-1"
      >
        <b-button
          class="form-control"
          variant="primary"
          @click="redirectOnHistory(coinbaseData.data.state)"
        >
          Voir la transaction
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import 'animate.css'
import { getPrivileges } from '@/auth/utils'
import {
  required,
  requiredIf,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import firebase from 'firebase/app'
import { v4 as uuidv4 } from 'uuid'
import encrypt from '@/libs/encrypt'
import 'firebase/storage'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      animation: null,
      camerounAdd: 30,
      showReceiveButton: false,
      showCoinbaseModal: false,
      coinbaseData: null,
      cryptoConverted: null,
      file: null,
      fileName: null,
      mobileInBenefit: 0,
      mobileOutBenefit: 0,
      count: 0,
      loadImage: false,
      progress: 0,
      interval: null,
      currentTicket: null,
      confirm: null,
      allCurrencies: [],
      loaded: false,
      receiver: null,
      selectedBuyCurrency: null,
      selectedSellCurrency: null,
      showModal: false,
      sentPaymentId: null,
      showBuyAddress: false,
      manuelPaymentAddress: null,
      sendManualAddressName: null,
      showSellAdress: false,
      currentTransaction: null,
      buy: null,
      buyItem: null,
      sell: null,
      sellItem: null,
      sendAmount: null,
      senderAdress: null,
      sender: null,
      receiveAmount: null,
      receiveAddress: null,
      showWaveBuyInfo: null,
      showWaveSellInfo: null,
      showVisaInfo: null,
      visaLastDigit: null,
      visaCardId: null,
      senderAddressName: null,
      receiverAddressName: null,
      countSent: 0,
      sendOptions: [],
      addresses: [],
      fetchedOptions: [],
      sellCurrencies: [],
      buyCurrencies: [],
      accounts: [],
      balances: [],
      countries: ['BF', 'CM', 'CI', 'GN', 'ML', 'SN'],
      minForDollar: 1,
      minForCrypto: 5,
      minForFCFA: 1000,
      minForGNF: 20000,
      minDollarToCrypto: 10,
      minForFCFAtoCrypto: 3000,
      minForFCFAtoBTCCrypto: 10000,
      minForGNFtoCrypto: 125000,
      minForGNFtoBTCCrypto: 300000,
      minForGNFtoGNF: 15000,
      translations: {
        countrySelectorLabel: 'Code',
        countrySelectorError: 'Pays',
        phoneNumberLabel: 'N°',
        example: 'Ex :',
      },
      phoneNumber: null,
      otp: null,
      cash_in_fees: 0,
      cash_out_fees: 0,
      applyFees: false,
      currentPath: null,
      fees_system: 0,
      isValidSender: false,
      isValidReceiver: false,
      numberOfValidTransaction: 0,

      // percent by country
      percent: {
        guinea: 0.04,
        cameroon: 0,
        ivory: 0,
        burkina: 0,
        mali: 0,
        senegal: 0,
      },
    }
  },
  validations: {
    selectedBuyCurrency: {
      required,
    },
    selectedSellCurrency: {
      required,
    },
    visaLastDigit: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        if (this.showVisaInfo) {
          return true
        }
        return false
      }),
      checkSize() {
        if (this.$v.visaLastDigit.$required) {
          if (this.visaLastDigit.length === 4) return true
        }
        return ' '
      },
      minLength: minLength(4),
      maxLength: maxLength(4),
    },
    visaCardId: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        if (this.showVisaInfo) {
          return true
        }
        return false
      }),
    },
    senderAddressName: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        if (this.showWaveBuyInfo) {
          return true
        }
        return false
      }),
    },
    receiverAddressName: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        if (this.showWaveSellInfo || this.showVisaInfo) {
          return true
        }
        return false
      }),
    },
    manuelPaymentAddress: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        if (this.showWaveBuyInfo) {
          return true
        }
        return false
      }),
    },
    file: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        if (this.showWaveBuyInfo) {
          return true
        }
        return false
      }),
      checkSize() {
        if (this.showWaveBuyInfo && this.file) {
          const filesize = this.file.size
          const file = Math.round(filesize / 1024 / 1024)
          if (file <= 5) {
            return true
          }
          return false
        }
        return true
      },
    },
    getReceivedAmount: {
      required,
      // eslint-disable-next-line func-names
      checkMax() {
        if ((this.getReceivedAmount) < this.sellItem.data.solde) {
          return true
        }
        return false
      },
      // minValue() {
      //   if (this.getReceivedAmount >= this.minForDollar) {
      //     return true
      //   } if (this.getReceivedAmount >= this.minForFCFA) {
      //     return true
      //   }
      //   return false
      // },
    },
    cash_in_fees: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        if (!this.isDollar(this.buy)) {
          return true
        }
        return false
      }),
    },
    cash_out_fees: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        if (!this.isDollar(this.sell)) {
          return true
        }
        return false
      }),
    },
    sendAmount: {
      required,
      // eslint-disable-next-line no-undef
      checkMin() {
        if (this.sendAmount) {
          if (this.isDollar(this.buy) && this.isDollar(this.sell) && !this.sellItem.data.is_crypto && this.sendAmount < this.minForDollar) {
            return false
          }
          if (this.isDollar(this.buy) && this.isDollar(this.sell) && this.sellItem.data.is_crypto && this.sendAmount < this.minDollarToCrypto) {
            return false
          } if (this.isDollar(this.buy) && this.sendAmount < this.minForDollar) {
            return false
          } if (!this.isDollar(this.buy) && this.sendAmount < this.minForFCFA) {
            return false
          } if (this.isDollar(this.buy) && this.buyItem.data.is_crypto && this.sendAmount < this.minForCrypto) {
            return false
          } if (!this.isDollar(this.buy) && this.checkFranc(this.buyItem.data.dev_code) && this.sellItem.data.is_crypto && (this.sellItem.data.dev_code !== 'BTC' || this.sellItem.data.dev_code !== 'BCH') && this.sendAmount < this.minForFCFAtoCrypto) {
            return false
          } if (!this.isDollar(this.buy) && this.checkFranc(this.buyItem.data.dev_code) && this.sellItem.data.is_crypto && (this.sellItem.data.dev_code === 'BTC' || this.sellItem.data.dev_code === 'BCH') && this.sendAmount < this.minForFCFAtoBTCCrypto) {
            return false
          } if (!this.isDollar(this.buy) && !this.checkFranc(this.buyItem.data.dev_code) && this.isDollar(this.sell) && this.sellItem.data.is_crypto && (this.sellItem.data.dev_code !== 'BTC' || this.sellItem.data.dev_code !== 'BCH') && this.sendAmount < this.minForGNFtoCrypto) {
            return false
          } if (!this.isDollar(this.buy) && !this.checkFranc(this.buyItem.data.dev_code) && this.isDollar(this.sell) && this.sellItem.data.is_crypto && (this.sellItem.data.dev_code === 'BTC' || this.sellItem.data.dev_code === 'BCH') && this.sendAmount < this.minForGNFtoBTCCrypto) {
            return false
          } if (!this.isDollar(this.buy) && !this.checkFranc(this.buyItem.data.dev_code) && this.isDollar(this.sell) && !this.sellItem.data.is_crypto && this.sendAmount < this.minForGNF) {
            return false
          } if (!this.isDollar(this.buy) && !this.checkFranc(this.buyItem.data.dev_code) && !this.isDollar(this.sell) && !this.checkFranc(this.sellItem.data.dev_code) && this.sendAmount < this.minForGNFtoGNF) {
            return false
          }
          return true
        }
        return ' '
      },
      checkOrangeAmount() {
        if (this.sendAmount) {
          if (this.checkOrange(this.buyItem.data.dev_code)) {
            if (this.buyItem.data.dev_code === 'OM_GUINEE') {
              if (this.sendAmount % 100 === 0) {
                return true
              }
              return false
            }
            if (this.sendAmount % 5 === 0) {
              return true
            }
            return false
          }
        }
        return ' '
      },
    },
    receiveAddress: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        if (!this.showVisaInfo) {
          return true
        }
        return false
      }),
      checkCorrectFormat() {
        if (this.showVisaInfo) {
          return true
        }
        if (!this.isDollar(this.sell)) {
          if (this.isValidReceiver) {
            return true
          }
          return false
        }
        return true
      },
    },
    senderAdress: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        if (!this.isDollar(this.buy)) {
          return true
        }
        return false
      }),
      checkCorrectFormat() {
        if (!this.isDollar(this.buy)) {
          if (this.isValidSender) {
            return true
          }
          return false
        }
        return true
      },
    },
    otp: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        if (this.buyItem.data.has_otp) {
          return true
        }
        return false
      }),
    },
  },
  computed: {
    ...mapGetters({
      success: 'exchangeSuccess',
      error: 'exchangeError',
      process: 'exchangeProcess',

      closeSuccess: 'closeExchangeSuccess',
      closeError: 'closeExchangeError',
      closeProcess: 'closeExchangeProcess',
    }),
    addressOptions() {
      const data = [
        {
          value: null,
          text: 'Choisir le numéro',
        },
      ]
      if (this.addresses.length > 0) {
        this.addresses.forEach(item => {
          const current = {
            text: item.address,
            value: item.id,
          }
          data.push(current)
        })
      }
      return data
    },
    sellOptions() {
      const data = []
      this.allCurrencies.forEach(element => {
        if (element.sale_status) {
          data.push(element)
        }
      })
      return data
    },
    buyOptions() {
      const data = []
      this.allCurrencies.forEach(element => {
        if (element.buy_status && element.dev_code !== 'visa') {
          data.push(element)
        }
      })
      return data
    },
    sellCurrenciesOptions() {
      const data = []
      this.sellOptions.forEach(item => {
        if (this.buy && item.id !== this.buy) {
          const current = {
            id: item.id,
            title: item.libelle,
            data: item,
            src: item.icones,
          }
          data.push(current)
        }
      })
      return data
    },
    buyCurrenciesOptions() {
      const data = []

      this.buyOptions.forEach(item => {
        const current = {
          id: item.id,
          title: item.libelle,
          data: item,
          src: item.icones,
        }
        data.push(current)
      })
      return data
    },
    mobilesOptions() {
      const data = []
      if (this.allCurrencies.length > 0) {
        this.allCurrencies.map(item => {
          if (item.ismobile) {
            data.push(item)
          }
          return data
        })
      }
      return data
    },
    getBuyFees() {
      let value = null
      // Si c'est du dolalr
      if (this.isDollar(this.buy)) {
        value = this.buyItem.data.partner_fees_out
      }
      // Si c'est mobile
      if (this.buyItem.data.ismobile) {
        // Si le fees est un pourcentage
        if (this.buyItem.data.partner_fees_out < 1) {
          const fees = (this.sendAmount / (1 - this.buyItem.data.partner_fees_out)) - this.sendAmount
          if (this.checkOrange(this.buyItem.data.dev_code)) {
            if (this.buyItem.data.dev_code === 'OM_GUINEE') {
              value = this.round100(this.roundToUp(fees))
            }
            value = this.roundUp5(this.roundToUp(fees))
          } else {
            value = this.roundUp5(this.roundToUp(fees))
          }
        } else {
          // Si le fees est constant
          value = this.buyItem.data.partner_fees_out
        }
      }
      // Si cest un moyen manuel
      if (!this.buyItem.data.is_auto) {
        value = this.sendAmount * this.buyItem.data.partner_fees_out
      }
      return value
    },
    getSaleFees() {
      if (this.$v.sendAmount.$touch && !this.$v.sendAmount.$anyError) {
        let sum = 0
        // FCFA to GNF
        if (this.checkFranc(this.buyItem.data.dev_code) && !this.checkFranc(this.sellItem.data.dev_code)) {
          // convert to GNF
          let converted = 0
          let percent = 0
          // check if buy currency is auto
          if (!this.buyItem.data.is_auto) {
            converted = (this.sendAmount - this.getBuyFees) / this.sellItem.data.sale
          } else {
            converted = this.sendAmount / this.sellItem.data.sale
          }
          if (!this.sellItem.data.is_auto) {
            sum = converted - (converted * percent)
          } else {
            percent = 0.04
            sum = converted - (converted * percent)
          }
        }
        // GNF to GNF
        if (!this.checkFranc(this.buyItem.data.dev_code) && !this.checkFranc(this.sellItem.data.dev_code)) {
          sum = this.sendAmount - (this.sendAmount * 0.02)
        }
        // GNF to FCFA
        if (!this.checkFranc(this.buyItem.data.dev_code) && this.checkFranc(this.sellItem.data.dev_code)) {
          let converted = 0
          if (!this.buyItem.data.is_auto) {
            converted = (this.sendAmount - this.getBuyFees) * this.buyItem.data.buy
          } else {
            converted = this.sendAmount * this.buyItem.data.buy
          }
          sum = converted
        }
        // FCFA to FCFA
        if (this.checkFranc(this.buyItem.data.dev_code) && this.checkFranc(this.sellItem.data.dev_code)) {
          sum = this.sendAmount - this.systemFees
        }
        // $ to FCFA
        if (this.isDollar(this.buy) && !this.isDollar(this.sell)) {
          // $ to FCFA
          if (this.checkFranc(this.sellItem.data.dev_code)) {
            sum = this.sendAmount * this.buyItem.data.buy
          }
          // $ to GNF
          if (!this.checkFranc(this.sellItem.data.dev_code)) {
            sum = (this.sendAmount * this.buyItem.data.buy) / this.sellItem.data.sale
          }
        }
        let fees = 0
        // Verifier si les fees sont en pourcentage
        if (this.sellItem.data.partner_fees_in < 1) {
          // Si oui, verifiez si c'est le reseau GSM Orange
          if (this.checkOrange(this.sellItem.data.dev_code)) {
            // Verifier si c'est Orange Guinee
            if (this.sellItem.data.dev_code === 'OM_GUINEE') {
              // Si oui, arrondir a la centaine pres
              fees = this.roundUp100((sum * this.sellItem.data.partner_fees_in))
            } else {
              // Si non, reduire a multiple de 5 le plus proche
              fees = this.roundUp5(this.roundToUp(sum * this.sellItem.data.partner_fees_in))
            }
          } else if (!this.checkOrange(this.sellItem.data.dev_code) && !this.sellItem.data.is_auto) {
            // Si ce n'est pas Orange
            fees = sum * this.sellItem.data.partner_fees_in
          } else {
            fees = this.roundToUp(sum * this.sellItem.data.partner_fees_in)
          }
        } else {
          fees = this.sellItem.data.partner_fees_in
        }
        return fees
      }
      return 0
    },
    systemFees() {
      let fees = 0
      if (!this.isDollar(this.buy) && !this.isDollar(this.sell)) {
        // GNF a FCFA
        if (!this.checkFranc(this.buyItem.data.dev_code) && this.checkFranc(this.sellItem.data.dev_code)) {
          // convertir GNF en FCFA
          let converted = 0
          let percent = 0
          const receiveDevCode = this.sellItem.data.dev_code.split('_')
          const lastElementOfReceiveDevCode = receiveDevCode[receiveDevCode.length - 1]
          if (!this.buyItem.data.is_auto) {
            converted = (this.sendAmount - this.getBuyFees) * this.buyItem.data.buy
          } else {
            converted = this.sendAmount * this.buyItem.data.buy
          }
          if (!this.sellItem.data.is_auto) {
            fees = 0
          } else {
            if (lastElementOfReceiveDevCode === 'CAMEROUN') {
              percent = 0.035
            } else {
              percent = 0.04
            }
            fees = converted * percent
          }
        }

        // FCFA a GNF
        if (this.checkFranc(this.buyItem.data.dev_code) && !this.checkFranc(this.sellItem.data.dev_code)) {
          // CONVERTIR FCFA en GNF
          let converted = 0
          let percent = 0
          if (!this.buyItem.data.is_auto) {
            converted = (this.sendAmount - this.getBuyFees) / this.sellItem.data.sale
          } else {
            converted = this.sendAmount / this.sellItem.data.sale
          }
          if (!this.sellItem.data.is_auto) {
            fees = converted * percent
          } else {
            percent = 0.04
            fees = converted * percent
          }
        }

        // GNF a GNF
        if (!this.checkFranc(this.buyItem.data.dev_code) && !this.checkFranc(this.sellItem.data.dev_code)) {
          const percent = 0.02
          fees = this.sendAmount * percent
        }

        // FCFA a FCFA
        if (this.checkFranc(this.buyItem.data.dev_code) && this.checkFranc(this.sellItem.data.dev_code)) {
          let percent = 0
          const sentDevCode = this.buyItem.data.dev_code.split('_')
          const receiveDevCode = this.sellItem.data.dev_code.split('_')
          const lastElementOfSentDevCode = sentDevCode[sentDevCode.length - 1]
          const lastElementOfReceiveDevCode = receiveDevCode[receiveDevCode.length - 1]
          if (this.buyItem.data.is_auto && this.sellItem.data.is_auto) {
            if (lastElementOfSentDevCode !== lastElementOfReceiveDevCode && (lastElementOfReceiveDevCode === 'CAMEROUN' || lastElementOfSentDevCode === 'CAMEROUN')) {
              percent = 0.035
            } else if ((lastElementOfSentDevCode === lastElementOfReceiveDevCode) || (lastElementOfSentDevCode !== lastElementOfReceiveDevCode)) {
              percent = 0.02
            }
            fees = this.sendAmount * percent
          } else if (this.buyItem.data.is_auto && (!this.sellItem.data.is_auto && this.sellItem.data.dev_code !== 'VISA')) {
            percent = 0.02
            fees = this.sendAmount * percent
          } else if (this.buyItem.data.is_auto && (!this.sellItem.data.is_auto && this.sellItem.data.dev_code === 'VISA')) {
            fees = this.sendAmount * percent
          } else if (!this.buyItem.data.is_auto && this.sellItem.data.is_auto) {
            if (lastElementOfSentDevCode !== lastElementOfReceiveDevCode && (lastElementOfReceiveDevCode === 'CAMEROUN' || lastElementOfSentDevCode === 'CAMEROUN')) {
              percent = 0.035
            } else {
              percent = 0.02
            }
            fees = this.sendAmount * percent
          } else if (!this.buyItem.data.is_auto && !this.sellItem.data.is_auto) {
            fees = this.sendAmount * percent
          }
        }
      }
      return fees
    },
    getReceivedAmount() {
      let value = 0
      if (this.$v.sendAmount.$touch && !this.$v.sendAmount.$anyError) {
        // $ to $
        if (this.isDollar(this.buy) && this.isDollar(this.sell)) {
          value = (this.sendAmount * this.buyItem.data.buy) / this.sellItem.data.sale
        }
        // $ to FCFA
        if (this.isDollar(this.buy) && !this.isDollar(this.sell)) {
          // $ to FCFA
          if (this.checkFranc(this.sellItem.data.dev_code)) {
            const sum = this.sendAmount * this.buyItem.data.buy
            let fees = 0
            if (this.sellItem.data.partner_fees_in < 1) {
              fees = sum * this.sellItem.data.partner_fees_in
            } else {
              fees = this.sellItem.data.partner_fees_in
            }
            value = sum - fees
          }
          // $ to GNF
          if (!this.checkFranc(this.sellItem.data.dev_code)) {
            const sum = (this.sendAmount * this.buyItem.data.buy) / this.sellItem.data.sale
            let fees = 0
            if (this.sellItem.data.partner_fees_in < 1) {
              fees = sum * this.sellItem.data.partner_fees_in
            } else {
              fees = this.sellItem.data.partner_fees_in
            }
            value = sum - fees
          }
        }
        // FCFA to $
        if (!this.isDollar(this.buy) && this.isDollar(this.sell)) {
          let realValue = 0
          if (!this.buyItem.data.is_auto) {
            realValue = this.sendAmount
          } else {
            realValue = this.checkFranc(this.buyItem.data.dev_code) ? this.sendAmount : this.sendAmount * this.buyItem.data.buy
          }
          value = realValue / this.checkCountry(this.sellItem.data.sale, this.sellItem.data.provider)
        }
        // FCFA to FCFA
        if (!this.isDollar(this.buy) && !this.isDollar(this.sell)) {
          // FCFA a GNF
          if (this.checkFranc(this.buyItem.data.dev_code) && !this.checkFranc(this.sellItem.data.dev_code)) {
            // CONVERTIR FCFA en GNF
            let converted = 0
            let realValue = 0
            let percent = 0
            if (!this.buyItem.data.is_auto) {
              converted = (this.sendAmount - this.getBuyFees) / this.sellItem.data.sale
            } else {
              converted = this.sendAmount / this.sellItem.data.sale
            }
            if (!this.sellItem.data.is_auto) {
              realValue = converted - (converted * percent)
            } else {
              percent = 0.04
              realValue = converted - (converted * percent)
            }
            let fees = 0
            if (this.sellItem.data.partner_fees_in < 1) {
              fees = realValue * this.sellItem.data.partner_fees_in
            } else {
              fees = this.sellItem.data.partner_fees_in
            }
            value = realValue - fees
          }
          // GNF a FCFA
          if (!this.checkFranc(this.buyItem.data.dev_code) && this.checkFranc(this.sellItem.data.dev_code)) {
            // convertir GNF en FCFA
            let converted = 0
            let realValue = 0
            let percent = 0
            const receiveDevCode = this.sellItem.data.dev_code.split('_')
            const lastElementOfReceiveDevCode = receiveDevCode[receiveDevCode.length - 1]
            if (!this.buyItem.data.is_auto) {
              converted = (this.sendAmount - this.getBuyFees) * this.buyItem.data.buy
            } else {
              converted = this.sendAmount * this.buyItem.data.buy
            }
            if (!this.sellItem.data.is_auto) {
              realValue = converted
            } else {
              if (lastElementOfReceiveDevCode === 'CAMEROUN') {
                percent = 0.035
              } else {
                percent = 0.04
              }
              realValue = converted - (converted * percent)
            }
            let fees = 0
            if (this.sellItem.data.partner_fees_in < 1) {
              fees = realValue * this.sellItem.data.partner_fees_in
            } else {
              fees = this.sellItem.data.partner_fees_in
            }
            value = realValue - fees
          }
          // GNF a GNF
          if (!this.checkFranc(this.buyItem.data.dev_code) && !this.checkFranc(this.sellItem.data.dev_code)) {
            const realValue = this.sendAmount - (this.sendAmount * 0.02)
            let fees = 0
            if (this.sellItem.data.partner_fees_in < 1) {
              fees = realValue * this.sellItem.data.partner_fees_in
            } else {
              fees = this.sellItem.data.partner_fees_in
            }
            value = realValue - fees
          }
          // FCFA a FCFA
          if (this.checkFranc(this.buyItem.data.dev_code) && this.checkFranc(this.sellItem.data.dev_code)) {
            let realValue = 0
            let converted = 0
            let percent = 0
            const sentDevCode = this.buyItem.data.dev_code.split('_')
            const receiveDevCode = this.sellItem.data.dev_code.split('_')
            const lastElementOfSentDevCode = sentDevCode[sentDevCode.length - 1]
            const lastElementOfReceiveDevCode = receiveDevCode[receiveDevCode.length - 1]
            if (this.buyItem.data.is_auto && this.sellItem.data.is_auto) {
              if (lastElementOfSentDevCode !== lastElementOfReceiveDevCode && (lastElementOfReceiveDevCode === 'CAMEROUN' || lastElementOfSentDevCode === 'CAMEROUN')) {
                percent = 0.035
              } else if ((lastElementOfSentDevCode !== lastElementOfReceiveDevCode) || (lastElementOfSentDevCode === lastElementOfReceiveDevCode)) {
                percent = 0.02
              }
              converted = this.sendAmount
              realValue = converted - (converted * percent)
            } else if (this.buyItem.data.is_auto && (!this.sellItem.data.is_auto && this.sellItem.data.dev_code !== 'VISA')) {
              if (lastElementOfSentDevCode === 'CAMEROUN') {
                percent = 0.035
              } else {
                percent = 0.02
              }
              converted = this.sendAmount
              realValue = converted - (converted * percent)
            } else if (this.buyItem.data.is_auto && (!this.sellItem.data.is_auto && this.sellItem.data.dev_code === 'VISA')) {
              if (lastElementOfSentDevCode === 'CAMEROUN') {
                percent = 0.035
              }
              converted = this.sendAmount - (this.sendAmount * percent)
              realValue = converted
            } else if (!this.buyItem.data.is_auto && this.sellItem.data.is_auto) {
              if (lastElementOfSentDevCode !== lastElementOfReceiveDevCode && lastElementOfReceiveDevCode === 'CAMEROUN') {
                percent = 0.035
              } else {
                percent = 0.02
              }
              converted = this.sendAmount
              realValue = converted - (converted * percent)
            } else if (!this.buyItem.data.is_auto && !this.sellItem.data.is_auto) {
              converted = this.sendAmount
              realValue = converted
            }
            let fees = 0
            if (this.sellItem.data.partner_fees_in < 1) {
              fees = realValue * this.sellItem.data.partner_fees_in
            } else if (this.sellItem.data.dev_code === 'VISA') {
              if (realValue > 0 && realValue <= 55000) {
                fees = this.sellItem.data.partner_fees_in
              } else if (realValue >= 55005) {
                fees = realValue * 0.02
              }
            } else {
              fees = this.sellItem.data.partner_fees_in
            }
            value = realValue - fees
          }
        }
      }
      let real = 0
      if (!this.isDollar(this.sell)) {
        if (this.sellItem.data.dev_code === 'OM_GUINEE') {
          real = this.round100(value)
        } else {
          real = this.round5(value)
        }
      } else {
        real = value
      }
      // eslint-disable-next-line no-nested-ternary
      return real > 0 ? this.isDollar(this.sell) === true ? real.toFixed(2) : Math.trunc(real.toFixed(2)) : 0
    },
    realReceivedAmount() {
      return this.getReceivedAmount - this.getSaleFees
    },
  },
  watch: {
    count() {
      if (this.count === 6) {
        this.loaded = false
        this.clear()
        this.closeDetailsModal()
        this.resetForm()
        this.correctRedirect()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Confirmation',
            icon: 'AlertCircleIcon',
            text: "Veuillez vérifier l'état du paiment dans votre historique.",
            variant: 'warning',
          },
        })
      }
    },
    progress(val) {
      if (val === 100) {
        this.loadImage = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Upload',
            icon: 'CheckCircleIcon',
            text: 'Fichier uploadé avec succès.',
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
      }
    },
    countSent() {
      if (this.countSent === 6) {
        this.loaded = false
        this.clear()
        this.closeDetailsModal()
        this.resetForm()
        this.redirectOnHistory(this.currentTransaction.state)
      }
    },
    $route: {
      immediate: true,
      handler() {
        this.getAllCurrencies()
        this.countExhange()
      },
    },
    currentTransaction(val) {
      if (val) {
        const response = val
        switch (response.sentState) {
          case 'PENDING':
            this.countSent += 1
            if (this.countSent < 6) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Ticket ${response.ticket}`,
                  icon: 'AlertCircleIcon',
                  text: 'En attente de réception.',
                  variant: 'warning',
                },
              },
              {
                position: 'top-center',
              })
            }
            break
          case 'INITIATED':
            this.countSent += 1
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Ticket ${response.ticket}`,
                icon: 'AlertCircleIcon',
                text: 'Paiment initié, veuillez confirmer.',
                variant: 'warning',
              },
            },
            {
              position: 'top-center',
            })
            break
          case 'FAILED':
            this.loaded = false
            this.closeDetailsModal()
            this.clear()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Ticket ${response.ticket}`,
                icon: 'XCircleIcon',
                text: "Paiment non effectué. Vous n'avez pas validé la transaction.",
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            })
            break
          case 'SUCCESSFUL':
            switch (response.receivedState) {
              case 'SUCCESSFUL':
                this.loaded = false
                this.clear()
                this.closeDetailsModal()
                this.resetForm()
                this.redirectOnHistory(this.currentTransaction.state)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `Ticket ${response.ticket}`,
                    icon: 'CheckCircleIcon',
                    text: 'Montant envoyé avec succes.',
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                })
                break
              case 'MANUAL_WAITING':
                this.loaded = false
                this.clear()
                this.redirectOnHistory(response.state)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Echange Semi Automatique',
                    icon: 'CheckCircleIcon',
                    text: 'Nous accusons réception de votre paiment. La transaction manuelle peut prendre quelques minutes (15 à 30 min).',
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                })
                break
              case 'WAITING':
              case 'PENDING':
                this.count += 1
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `Ticket ${response.ticket}`,
                    icon: 'AlertCircleIcon',
                    text: 'Paiment en cours. Veuillez verifier la transaction avec le ticket',
                    variant: 'warning',
                  },
                },
                {
                  position: 'top-center',
                })
                break
              case 'FAILED':
                this.loaded = false
                this.closeDetailsModal()
                this.clear()
                this.resetForm()
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `Ticket ${response.ticket}`,
                    icon: 'XCircleIcon',
                    text: 'Une erreur est survenue lors du tranfert. Veuillez nous contacter via le support.',
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                })
                break
              default:
                break
            }
            break
          default:
            break
        }
      }
    },
    manuelPaymentAddress(val) {
      if (val) {
        // eslint-disable-next-line no-restricted-syntax
        for (const item in this.addresses) {
          if (this.addresses[item].id === val) {
            this.sendManualAddressName = this.addresses[item].address
          }
        }
      }
    },
    selectedBuyCurrency(val) {
      if (val) {
        this.clearValue()
        if (!val.data.is_auto && val.data.dev_code.split('_')[0] === 'WAVE') {
          this.showWaveBuyInfo = true
          this.getPaymentAddress(val.data.id)
        } else {
          this.showWaveBuyInfo = false
        }
        this.buy = val.id
        this.buyItem = val
        this.cash_in_fees = val.data.partner_fees_in
      }
    },
    selectedSellCurrency(val) {
      if (val) {
        this.receiver = null
        this.receiveAddress = null
        if (!val.data.is_auto && val.data.dev_code.split('_')[0] === 'WAVE') {
          this.showWaveSellInfo = true
        } else {
          this.showWaveSellInfo = false
        }
        if (!val.data.is_auto && val.data.dev_code === 'VISA') {
          this.showVisaInfo = true
        } else {
          this.receiverAddressName = null
          this.visaCardId = null
          this.visaLastDigit = null
          this.showVisaInfo = false
        }
        this.sell = val.id
        this.sellItem = val
        this.cash_out_fees = val.data.partner_fees_out
      }
    },
    success(val) {
      if (val) {
        if (this.isDollar(this.buy)) {
          if (this.buyItem.data.provider === 'COINPAYMENT') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Confirmer le paiment',
                icon: 'AlertCircleIcon',
                text: 'Redirection en cours.',
                variant: 'warning',
              },
            })
            window.location = val.data.data.checkoutUrl
          } else if (
            this.buyItem.data.provider === 'PAYEER'
            || this.buyItem.data.provider === 'PERFECT'
          ) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Confirmer le paiment',
                icon: 'AlertCircleIcon',
                text: 'Redirection en cours.',
                variant: 'warning',
              },
            })
            window.location = val.request.responseURL
          } else if (this.buyItem.data.provider === 'COINBASE') {
            this.closeDetailsModal()
            this.clear()
            this.clearValue()
            this.openCoinbaseModal(val.data)
          }
        } else if (this.buyItem.data.dev_code === 'OM_CI') {
          window.location = val.data.payment_url
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Confirmer le paiment',
              icon: 'AlertCircleIcon',
              text: 'Redirection sur Orange en cours.',
              variant: 'warning',
            },
          })
        } else if (!this.buyItem.data.is_auto) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Paiment Manuel',
              icon: 'CheckCircleIcon',
              text: 'Cette opération peut prendre quelques minutes (5 à 30 min) pour le traitment.',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          })
          this.redirectOnHistory(val.data.data.state)
        } else if (this.buyItem.data.is_auto && !this.sellItem.data.is_auto) {
          this.loaded = true
          const response = val.data
          // Verifier l'etat de la premiere transaction
          switch (response.data.sentState) {
            case 'SUCCESSFUL':
              // Verifier l'etat de la seconde transaction
              this.clear()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Paiment Manuel',
                  icon: 'CheckCircleIcon',
                  text: `${val.data.message}. Cette opération peut prendre quelques minutes (5 à 15min).`,
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              })
              this.redirectOnHistory(val.data.data.state)
              break
            case 'PENDING':
            case 'INITIATED':
              this.interval = setInterval(() => {
                this.getCurrentTicket(response.data.ticket)
              }, 10000)
              break
            case 'FAILED':
              this.clear()
              this.closeDetailsModal()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Ticket',
                  icon: 'XCircleIcon',
                  text: "Une erreur est survenue lors de l'initiation de la transaction. Veuillez rééssayer",
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              })
              break
            default:
              break
          }
        } else if ((this.buyItem.data.is_auto) && (this.sellItem.data.is_auto)) {
          this.loaded = true
          const response = val.data
          // Verifier l'etat de la premiere transaction
          switch (response.data.sentState) {
            case 'SUCCESSFUL':
              // Verifier l'etat de la seconde transaction
              switch (response.data.receivedState) {
                case 'SUCCESSFUL':
                  this.loaded = false
                  this.clear()
                  this.closeDetailsModal()
                  this.resetForm()
                  this.redirectOnHistory(response.data.state)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `Ticket ${response.ticket}`,
                      icon: 'CheckCircleIcon',
                      text: 'Montant envoyé avec succes.',
                      variant: 'success',
                    },
                  },
                  {
                    position: 'top-center',
                  })
                  break
                case 'WAITING':
                case 'PENDING':
                  this.getCurrentTicket(response.data.ticket)
                  break
                case 'FAILED':
                  this.loaded = false
                  this.clear()
                  this.closeDetailsModal()
                  this.resetForm()
                  this.redirectOnHistory(response.data.state)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Echange',
                      icon: 'XCircleIcon',
                      text: "Dépôt non réussi. Veuillez contacter l'administrateur.",
                      variant: 'danger',
                    },
                  },
                  {
                    position: 'top-center',
                  })
                  break
                default:
                  break
              }
              break
            case 'PENDING':
            case 'INITIATED':
              this.interval = setInterval(() => {
                this.getCurrentTicket(response.data.ticket)
              }, 10000)
              break
            case 'FAILED':
              this.clear()
              this.closeDetailsModal()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Ticket',
                  icon: 'XCircleIcon',
                  text: "Une erreur est survenue lors de l'initiation de la transaction. Veuillez rééssayer",
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              })
              break
            default:
              break
          }
        }
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ticket',
            icon: 'XCircleIcon',
            text: 'Transaction non effectuée.',
            variant: 'danger',
          },
        },
        {
          position: 'top-center',
        })
      }
    },
    closeSuccess(val) {
      if (val) {
        const res = val.data
        switch (res.data.receivedState) {
          case 'PENDING':
            this.getCurrentTicket(res.data.id)
            break
          case 'SUCCESSFUL':
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Ticket ${res.data.ticket}`,
                icon: 'CheckCircleIcon',
                text: 'CASHIN effectuee avec succes.',
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            })
            break
          case 'FAILED':
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Ticket ${res.data.ticket}`,
                icon: 'XCircleIcon',
                text: 'CASHIN erreur.',
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            })
            break
          default:
            break
        }
      }
    },
    closeError() {
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions(['makeExchange', 'closeMobileExchange']),
    capitalize(item) {
      const words = item.split(' ')
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase()
      }
      return words.join(' ')
    },
    truncate(item) {
      const length = 20
      return item.length <= length ? item : `${item.substring(0, length)}...`
    },
    checkCountry(item, provider) {
      const receiveDevCode = this.buyItem.data.dev_code.split('_')
      const lastElementOfReceiveDevCode = receiveDevCode[receiveDevCode.length - 1]
      if (lastElementOfReceiveDevCode === 'CAMEROUN' && (['COINBASE', 'COINPAYMENT', 'PERFECT', 'PAYEER'].includes(provider))) {
        return (item + this.camerounAdd)
      }
      return item
    },
    openCoinbaseModal(item) {
      this.showCoinbaseModal = true
      this.coinbaseData = item
    },
    closeCoinbaseModal() {
      this.showCoinbaseModal = false
    },
    async getConvertedValue() {
      this.cryptoConverted = null
      this.cryptoConverted = await this.$http
        .get(`/convert-coin-data?amount=${this.sellItem.data.partner_fees_out}&from=${this.sellItem.data.dev_code.split('_')[0]}&to=USD`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.currentToken}`,
            },
          })
        .then(res => res.data.data.quote.USD.price.toFixed(2))
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Equivalence USD',
              icon: 'XCircleIcon',
              text: 'Une erreur est survenue lors de la convertion.',
              variant: 'danger',
            },
          })
          return 0
        })
    },
    async countExhange() {
      this.numberOfValidTransaction = await this.$http
        .get(`/stats-by-state-and-owner/${this.$store.getters.currentUser.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.currentToken}`,
            },
          })
        .then(res => {
          const { data } = res
          let number = 0
          if (data.length > 0) {
            data.forEach(element => {
              if (element.state === 'SUCCESSFUL') {
                number = Number(element.data)
              }
            })
          }
          return number
        })
        .catch(() => [])
    },
    checkSendValidity(item) {
      if (item.valid) {
        this.sender = item.nationalNumber
        this.isValidSender = true
      } else {
        this.isValidSender = false
        this.sender = null
      }
    },
    checkReceivedValidity(item) {
      if (item.valid) {
        this.receiver = item.nationalNumber
        this.isValidReceiver = true
      } else {
        this.receiver = null
        this.isValidReceiver = false
      }
    },
    updateSender() {
      this.senderAddress = null
      this.isValidSender = false
    },
    updateReceiver() {
      // this.receiverAddress = null
      this.isValidReceiver = false
    },
    onAddressCopy(item) {
      const container = document.getElementById('btc-address')
      this.$copyText(item, container).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copie',
            icon: 'CheckSquareIcon',
            text: `${item}`,
            variant: 'success',
          },
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copie',
            icon: 'XCircleIcon',
            text: 'Copie échouée.',
            variant: 'danger',
          },
        })
      })
    },
    onValueCopy(item) {
      const container = document.getElementById('btc-value')
      this.$copyText(item, container).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copie',
            icon: 'CheckSquareIcon',
            text: `${item}`,
            variant: 'success',
          },
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copie',
            icon: 'XCircleIcon',
            text: 'Copie échouée.',
            variant: 'danger',
          },
        })
      })
    },
    onCopy(item) {
      const container = document.getElementById('copy')
      this.$copyText(item, container).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'N° de Paiment Wave',
            icon: 'CheckSquareIcon',
            text: `${item} Copié avec succès.`,
            variant: 'success',
          },
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'N° de Paiment Wave',
            icon: 'XCircleIcon',
            text: 'Copie échouée.',
            variant: 'danger',
          },
        })
      })
    },
    tryToExchange() {
      this.$v.selectedBuyCurrency.$touch()
      this.$v.selectedSellCurrency.$touch()
      this.$v.visaLastDigit.$touch()
      this.$v.visaCardId.$touch()
      this.$v.senderAddressName.$touch()
      this.$v.receiverAddressName.$touch()
      this.$v.manuelPaymentAddress.$touch()
      this.$v.getReceivedAmount.$touch()
      this.$v.cash_in_fees.$touch()
      this.$v.cash_out_fees.$touch()
      this.$v.sendAmount.$touch()
      this.$v.receiveAddress.$touch()
      this.$v.senderAdress.$touch()
      this.$v.otp.$touch()
      if (this.$v.selectedBuyCurrency.$anyError
        || this.$v.selectedSellCurrency.$anyError
        || this.$v.visaLastDigit.$anyError
        || this.$v.visaCardId.$anyError
        || this.$v.senderAddressName.$anyError
        || this.$v.receiverAddressName.$anyError
        || this.$v.manuelPaymentAddress.$anyError
        || this.$v.getReceivedAmount.$anyError
        || this.$v.cash_in_fees.$anyError
        || this.$v.cash_out_fees.$anyError
        || this.$v.sendAmount.$anyError
        || this.$v.receiveAddress.$anyError
        || this.$v.senderAdress.$anyError
        || this.$v.otp.$anyError
      ) {
        this.animation = 'animate-wrong'

        setTimeout(() => {
          this.animation = null
        }, 1000)
        return
      }
      this.openDetailsModal()
    },
    formatter(value) {
      return value.toUpperCase()
    },
    getCurrentCurrency(item) {
      let currency = null
      if (this.isDollar(item.id)) {
        currency = this.checkCurrency(item.dev_code)
      } else {
        currency = this.checkFranc(item.dev_code) ? 'FCFA' : 'GNF'
      }
      return currency
    },
    // async uploadFile(event) {
    //   [this.file] = event.target.files
    // },
    async uploadFile(event) {
      const document = Object.values(event.target.files)[0]
      this.loadImage = true
      const self = this
      const storageRef = firebase.storage().ref(`TRANSACTIONS/WAVE/${encrypt.encode(this.$store.getters.currentUser.email)}-${uuidv4()}`)
      // Upload file to Firebase Storage
      const uploadTask = storageRef.put(document)
      uploadTask.on('state_changed', snapshot => {
        self.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        if (self.progress < 100) {
          self.loadImage = true
        }
      }, () => {
        this.loadImage = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Upload',
            icon: 'XCircleIcon',
            text: 'Une erreur est survenue lors du téléchargement du fichier.',
            variant: 'danger',
          },
        },
        {
          position: 'top-center',
        })
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(url => {
          this.fileName = url
          this.loadImage = false
        })
      })
    },
    roundUp5(x) {
      return Math.ceil(x / 5) * 5
    },
    roundUp100(x) {
      return Math.ceil(x / 100) * 100
    },
    round5(x) {
      return Math.floor(x / 5) * 5
    },
    round100(x) {
      return Math.floor(x / 100) * 100
    },
    checkOrange(item) {
      if (item.split('_')[0] === 'OM') return true
      return false
    },
    correctRedirect() {
      const path = getPrivileges() === 'client' ? 'client-history' : 'admin-history'
      this.$router.push({ name: path })
    },
    redirectOnHistory(item) {
      const path = getPrivileges() === 'client' ? '/history' : '/admin-history'
      this.$router.push(`${path}?state=${item}`)
    },
    kycRedirect() {
      const path = getPrivileges() === 'client' ? '/client-kyc-submit' : '/admin-kyc-submit'
      this.$router.push(path)
    },
    clear() {
      clearInterval(this.interval)
    },
    totalReceiveAmount() {
      return 0
    },
    roundToUp(item) {
      return Math.trunc(item) + 1
    },
    amountRound(item) {
      return Math.trunc(item)
    },
    async getCurrentTicket(item) {
      this.loaded = true
      this.currentTransaction = await this
        .$http
        .get(`/find-all-by-ticket/${item}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => res.data)
        .catch(() => null)
    },
    resetForm() {
      this.selectedBuyCurrency = null
      this.selectedSellCurrency = null
      this.sendAmount = null
      this.senderAdress = null
      this.receiver = null
      this.receiveAddress = null
      this.sender = null
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    clearValue() {
      this.selectedSellCurrency = null
      this.sell = null
      this.sellItem = null
      this.sendAmount = null
      this.receiver = null
      this.receiveAddress = null
      this.sender = null
      this.senderAdress = null
      this.showWaveBuyInfo = null
      this.showWaveSellInfo = null
      this.showVisaInfo = null
      this.visaLastDigit = null
      this.visaCardId = null
      this.senderAddressName = null
      this.receiverAddressName = null
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    update(event) {
      if (event) {
        this.receiver = event.nationalNumber
      }
    },
    isDollar(checkedValue) {
      if (this.buy) {
        if (this.sellOptions.filter(item => item.id === checkedValue)[0].dev_code === 'VISA') {
          return false
        }
        const index = this.mobilesOptions.findIndex(item => item.id === checkedValue)
        if (index === -1) {
          return true
        }
        return false
      }
      return null
    },
    checkFranc(item) {
      if (item.split('_')[1] === 'GUINEE') {
        return false
      }
      return true
    },
    checkCurrency(item) {
      if (item.split('_')[1] === 'EURO') {
        return '€'
      }
      return '$'
    },
    async getAllCurrencies() {
      this.allCurrencies = await
      this.$http
        .get('/currencies/all', {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => res.data.data)
        .catch(() => [])
    },
    async getPaymentAddress(id) {
      this.addresses = await this.$http
        .get(`/tarif-receipt-address/find-by-tarif/${id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => {
          const { data } = res.data
          return data.filter(item => Number(item.state) === 1)
        })
        .catch(() => [])
    },
    openDetailsModal() {
      if (this.sellItem.data.is_crypto && this.sellItem.data.provider === 'COINPAYMENT' && this.sellItem.data.partner_fees_out !== 0) {
        this.getConvertedValue()
      }
      this.showModal = true
    },
    closeDetailsModal() {
      this.showModal = false
      this.file = null
      this.$nextTick(() => {
        this.$v.file.$reset()
      })
      this.fileName = null
      this.confirm = null
    },
    tryToCloseExchange(item) {
      this.closeMobileExchange(item)
    },
    confirmExchange() {
      if (this.$store.getters.currentUser.verification_state === 'VALID') {
        this.$v.file.$touch()
        if (this.$v.file.$anyError) {
          return
        }
        let memo = null
        if (this.isDollar(this.buy)) {
          memo = `Envoi de ${this.sendAmount} ${this.checkCurrency(this.buyItem.data.dev_code)} de ${this.buyItem.data.libelle} à ${this.sellItem.data.libelle}`
        } else {
          memo = `Envoi de ${this.sendAmount} ${this.checkFranc(this.buyItem.data.dev_code) ? 'FCFA' : 'GNF'} de ${this.buyItem.data.libelle} à ${this.sellItem.data.libelle}`
        }

        let receiver = null
        let amount = 0
        if (this.showVisaInfo) {
          receiver = this.visaCardId
        } else {
          receiver = this.receiver ? this.receiver : this.receiveAddress
        }
        if (this.sellItem.data.dev_code === 'PAYEER_EURO' || this.sellItem.data.dev_code === 'PAYEER_USD') {
          amount = Number(this.getReceivedAmount) + (Number(this.getReceivedAmount) * this.sellItem.data.partner_fees_in)
        } else {
          amount = Number(this.getReceivedAmount)
        }
        this.makeExchange({
          sentDevCode: this.buyItem.data.dev_code,
          receivedDevCode: this.sellItem.data.dev_code,
          sentMemo: memo,
          sentValue: this.sendAmount + this.getBuyFees,
          manualRecipientAddressId: this.sendManualAddressName,
          sentPaymentId: this.sentPaymentId,
          realSentValue: this.sendAmount,
          receivedValue: Number(amount),
          receiverAddress: receiver,
          visaLastDigit: this.visaLastDigit,
          mobileOutBenefit: this.systemFees,
          mobileInBenefit: this.mobileInBenefit,
          visaCardId: this.visaCardId,
          senderAddressName: this.senderAddressName,
          receiverAddressName: this.receiverAddressName,
          senderAddress: this.sender ? this.sender : this.senderAdress,
          otp: this.buyItem.data.has_otp ? this.otp : null,
          fileName: this.fileName,
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vérification de Compte',
            icon: 'AlertCircleIcon',
            text: "Veuillez procéder a la validation de votre compte pour procéder à l'échange",
            variant: 'warning',
          },
        })
        this.kycRedirect()
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

  .cursor {
    cursor: pointer;
  }
  [dir=ltr] .vue-tel-input {
    text-align: left;
    padding: 0.25rem 0.1rem;
  }

  /* .vue-phone-number-input {
    .input-tel__input {
      background-color: transparent;
      color: #126e51;
    }
    .country-selector__input {
      background-color: transparent;
      color: #126e51;
    }
  } */
.animate-wrong {
  animation: wrong 0.4s ease-in-out;
}

@keyframes wrong {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(40px);
  }
  40% {
    transform: translateX(20px);
  }
  60% {
    transform: translateX(40px);
  }
  80% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
